import {createRouter, createWebHashHistory} from 'vue-router'
import Home from '../views/HomePage.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import('../views/Download.vue')
  },
  {
    path: '/om',
    name: 'OpenMiniprogram',
    component: () => import('../views/Miniprogram.vue')
  },
  {
    path: '/ttad', // 抖音广告
    name: 'TicktokAd',
    component: () => import('../views/TicktokAd.vue')
  },
  {
    path: '/opxbc', // 打开小包车
    name: 'OpenXbc',
    component: () => import('../views/OpenXbc.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
