<template>
  <div class="container">
    <img class="top-img" src="../assets/images/top-img.jpg" alt="">
    <div class="reg-box">
      <div class="reg-title">立即注册</div>
      <div class="reg-content">
        <div class="input-box flex-left-row">
          <span class="iconfont icon-shouji_o"></span>
          <input class="input" v-model="form.mobile" type="number" placeholder="请输入手机号码">
        </div>
        <div class="input-box flex-left-row">
          <span class="iconfont icon-yanzhengma"></span>
          <input class="input" v-model="form.ver_code" type="number" placeholder="请输入验证码">
          <div v-if="!disabled" class="send-code" @click="sendSms">发送验证码</div>
          <div v-else class="send-code disabled">重试({{ second }}s)</div>
        </div>
        <div class="input-box flex-left-row">
          <span class="iconfont icon-password"></span>
          <input class="input" v-model="form.password" type="password" placeholder="请输入密码，该密码用于登录APP">
        </div>
        <div class="btn-box">
          <button class="button" @click="handleRegister">立即注册</button>
          <span>注册成功后可下载APP</span>
        </div>
      </div>
    </div>
    <div class="reg-box">
      <div class="reg-title">司机权益</div>
      <div v-if="area.reg_rebate_fee" class="reg-content-rule">
        <span>1、司机推荐客户，客户注册成功，奖励司机{{ area.reg_rebate_fee }}元</span>
        <span>2、乘客第一次使用城区线上约车，行程完成即可奖励第一推荐司机{{ area.first_order_fee }}元并且收益{{ area.first_order_rate * 100 + '%' }}</span>
        <span>3、乘客第二次使用城区线上约车，行程完成即可奖励第一推荐司机{{ area.second_order_fee }}元并且收益{{ area.second_order_rate * 100 + '%' }}</span>
        <span>4、乘客终身，任何区域使用线上约车，完成司机收益{{ area.forever_order_rate * 100 + '%' }}</span>
        <span>5、司机推荐司机注册，完成接单{{ area.order_count }}单，奖励推荐司机{{ area.rebate_fee }}元</span>
      </div>
    </div>
  </div>
</template>

<script>
import {onBeforeMount, reactive, toRefs, getCurrentInstance} from 'vue'
import axios from 'axios'
import Toast from '@/utils/toast'
import { useRouter } from 'vue-router'

export default {
  name: 'Home',
  setup() {
    const $router = useRouter()
    const { proxy } = getCurrentInstance()
    const state = reactive({
      // baseApi: 'http://192.168.124.74',
      baseApi: 'https://dapi.kuaiguiqx.com',
      uid: '',
      form: {
        uid: '',
        mobile: '',
        ver_code: '',
        password: '',
        gender: 1,
        aid: ''
      },
      url: '',
      second: 60,
      disabled: false,
      area: {}
    })

    const sendSms = () => {
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (!state.form.mobile) {
        Toast('请输入手机号码')
        return
      }
      if (!reg.test(state.form.mobile)) {
        Toast('手机号码格式不正确')
        return
      }
      axios.get(`${state.baseApi}/auth/mobile/ver_code`, {
        params: {
          mobile: state.form.mobile
        }
      }).then(res => {
        if (res.status === 200 && res.data.code === 0) {
          timer()
        }
      }).catch(err => {
        Toast(err.message)
        console.log(err)
      })
    }

    const getActivityRule = (aid) => {
      axios.get(`${state.baseApi}/user/area/${aid}`).then(res => {
        state.area = res.data.data
      })
    }

    const timer = () => {
      state.disabled = true
      const interval = setInterval(() => {
        if (state.second === 0) {
          state.disabled = false
          state.second = 60
          clearInterval(interval)
        } else {
          state.second--
        }
      }, 1000)
    }

    const handleRegister = () => {
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (!state.form.mobile) {
        Toast('请输入手机号码')
        return
      }
      if (!reg.test(state.form.mobile)) {
        Toast('手机号码格式不正确')
        return
      }
      if (!state.form.ver_code) {
        Toast('请输入短信验证码')
        return
      }
      if (!state.form.password) {
        Toast('请输入密码')
        return
      }
      axios.post(`${state.baseApi}/auth/mobile/register`, state.form).then(res => {
        if (res.status === 200 && res.data.code === 0) {
          if (confirm('注册成功,去下载')) {
            $router.push('/download')
          } else {
            console.log('取消下载')
          }
        } else {
          Toast('注册失败')
        }
      }).catch(err => {
        if (err.response.data.message === '手机号码已存在') {
          if (confirm('账号已存在，去下载')) {
            $router.push('/download')
          } else {
            console.log('取消下载')
          }
        } else {
          Toast(err.response.data.message)
        }
      })
    }

    onBeforeMount(() => {
      const query = proxy.$route.query.query
      const aid = proxy.$route.query.aid
      state.form.uid = parseInt(query)
      state.form.aid = parseInt(aid)
      getActivityRule(aid)
    })

    return {
      ...toRefs(state),
      getActivityRule,
      sendSms,
      timer,
      handleRegister
    }
  }
}
</script>

<style>
@import '//at.alicdn.com/t/font_2935912_ancag0zauqh.css';

* {
  outline: none;
}

html, body {
  margin: 0;
  padding: 0;
  outline: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.flex-between-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-left-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.container {
  min-height: 100vh;
  background-color: #fda158;
  padding-bottom: 30px;
}

.container .top-img {
  width: 100%;
  min-height: 397px;
}

.reg-box {
  position: relative;
  margin-top: 20px;
  width: 94%;
  left: 3%;
  border-radius: 6px;
  background-color: #fbf0e6;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 40px;
}

.reg-box .reg-title {
  position: absolute;
  top: -17px;
  width: 143px;
  height: 33px;
  line-height: 33px;
  font-weight: bold;
  color: #fff;
  left: 50%;
  margin-left: -71px;
  background-image: url("../assets/images/title-bg.png");
  background-size: cover;
}

.reg-content {
  margin-top: 10px;
}

.input-box {
  margin-bottom: 15px;
  background-color: #fff;
  padding: 5px 6px;
  border-radius: 3px;
}

.input-box .input {
  flex: 1;
  border: 0;
  padding: 10px 10px 8px 10px;
  outline: none;
  font-size: 14px;
}

.input-box .send-code {
  font-size: 14px;
  background-color: #fd745e;
  color: #fff;
  padding: 5px 8px;
  margin-right: 3px;
  border-radius: 3px;
}

.input-box .disabled {
  background-color: #ddd;
  color: #464646;
}

.btn-box .button {
  border: 0;
  background: #fd745e;
  width: 100%;
  height: 45px;
  border-radius: 3px;
  color: #fff;
  font-size: 16px;
  outline: none;
}

.btn-box span {
  display: block;
  padding-top: 10px;
  font-size: 14px;
  color: #999;
}

.reg-content-rule {
  text-align: left;
}

.reg-content-rule span {
  display: block;
  font-size: 13px;
}

.cpt-toast {

}
</style>
